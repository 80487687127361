





































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'

@Component({
  components: {
    ListView,
    PageTab,
  },
  directives: {
    mask,
  },
})
export default class List extends Vue {
  public $refs: Vue['$refs'] & {
    newConfigForm: {
      validate: any
      reset: any
    }
  }

  private loading = true

  private configurationName = ''
  private locationId: number = null
  private fetchedLocations = []
  private active = false
  private notifyRequired = false

  private inputPeriodFrom = null
  private inputPeriodTo = null

  private stockRefillDialog = false

  private headers = []

  private rowActions = []
  private topActions = []

  private filterQuantityToOrder = false

  private rules = {
    periodRules: [
      (v) =>
        !v ||
        /^(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])$/.test(v) ||
        this.$t('c:stock-refill:Invalid period (ex: MM.dd)'),
    ],
  }

  created() {
    this.setHeaders()
    this.setTopActions()
    this.loading = false

    this.rowActions = [
      {
        id: 'show',
        route: (item) => {
          return {
            name: 'Stock/Stock-Refill/StockRefillConfiguration/Details',
            params: { id: item.id },
          }
        },
      },
      {
        id: 'delete',
      },
    ]
  }

  private submitForm(): void {
    if ((this.inputPeriodFrom && !this.inputPeriodTo) || (!this.inputPeriodFrom && this.inputPeriodTo)) {
      vxm.alert.error('Either both periods or none should be empty')
      return
    }
    if (this.$refs.newConfigForm.validate()) {
      this.loading = true
      const data = {
        locationId: this.locationId,
        name: this.configurationName,
        periodFrom: this.inputPeriodFrom,
        periodTo: this.inputPeriodTo,
        active: this.active,
        notifyRequired: this.notifyRequired,
        userId: null,
      }
      this.$axios
        .post(this.url, data)
        .then(() => {
          this.locationId = null
          this.configurationName = ''
          this.inputPeriodFrom = null
          this.inputPeriodTo = null
          this.active = false
          this.loading = false
          this.stockRefillDialog = false
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error saving new refill')
        })
    }
  }

  private newStockRefillDialog(): void {
    this.listLocations()
    this.stockRefillDialog = true
  }

  private get url() {
    return '/v4/site/stock-refill-configurations'
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'Name',
        value: 'name',
        filter: { disable: true },
      },
      {
        text: 'Location',
        value: 'location.name',
        filter: { disable: true },
        sortable: false,
      },
      {
        text: 'Active',
        value: 'active',
        filter: { disable: true },
      },
      {
        text: 'c:stock-refill:Notify required',
        value: 'notifyRequired',
        filter: { disable: true },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }

  private setTopActions() {
    this.topActions = [
      {
        id: 'new',
        template: 'new',
        click: () => {
          this.newStockRefillDialog()
        },
      },
    ]
  }

  private listLocations() {
    this.$axios
      .get('/v4/site/stock-refill-configurations/locations')
      .then((res) => {
        res.data.data.items.forEach((item) => {
          this.fetchedLocations.push(item)
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading locations')
      })
  }

  private formatYesNo(value) {
    if (value === true) {
      return this.$t('Yes')
    }
    return this.$t('No')
  }

  private formatLocation(refill) {
    if (refill.locationId === null) {
      return this.$t('All locations')
    }
    return refill.location.name
  }

  private formatActive(status) {
    if (status === 1) {
      return this.$t('Yes')
    }
    return this.$t('No')
  }

  private get query() {
    return {
      showOnlyQuantityToOrderRefills: this.filterQuantityToOrder,
    }
  }
}
