var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',[_c('page-tab',{attrs:{"title":'c:stock-refill:Stock Refill Configurations'}}),(_vm.loading)?[_c('v-row',{staticClass:"my-6",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100"}})],1)]:[_c('list-view',{attrs:{"headers":_vm.headers,"url":_vm.url,"query":_vm.query,"row-actions":_vm.rowActions,"top-actions":_vm.topActions,"loading":_vm.loading,"sort-by":"name","items-per-page":100,"enable-filters":false,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250] }},scopedSlots:_vm._u([{key:"toolbar-actions-append",fn:function(){return [_c('v-row',{staticClass:"d-inline-flex",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('c:stock-refill:Show only configs that have products that need reordering'),"hide-details":"","dense":""},model:{value:(_vm.filterQuantityToOrder),callback:function ($$v) {_vm.filterQuantityToOrder=$$v},expression:"filterQuantityToOrder"}})],1)],1)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatActive(item.status))+" ")]}},{key:"item.location.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatLocation(item))+" ")]}},{key:"item.notifyRequired",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatYesNo(item.notifyRequired))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"blue--text",attrs:{"to":{
              name: 'Stock/Stock-Refill/StockRefillConfiguration/Details',
              params: {
                id: item.id,
              },
            }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}}])})]],2),_c('v-dialog',{attrs:{"content-class":"stock-refill-create-form-dialog","max-width":"400"},model:{value:(_vm.stockRefillDialog),callback:function ($$v) {_vm.stockRefillDialog=$$v},expression:"stockRefillDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('c:stock-refill:New stock refill configuration'))+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-form',{ref:"newConfigForm"},[_c('v-container',{staticStyle:{"padding":"0"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('c:stock-refill:Refill configuration name'),"rules":[function (v) { return !!v || _vm.$t('c:stock-refill:Name is required'); }],"required":""},model:{value:(_vm.configurationName),callback:function ($$v) {_vm.configurationName=$$v},expression:"configurationName"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-select',{attrs:{"label":"Location","items":_vm.fetchedLocations,"item-value":"id","item-text":"name"},model:{value:(_vm.locationId),callback:function ($$v) {_vm.locationId=$$v},expression:"locationId"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('h4',[_vm._v(_vm._s(_vm.$t('c:stock-refill:Active period')))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("fa-info-circle")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('c:stock-refill:If both periods are cleared, it indicates year-round activity status')))])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.##'),expression:"'##.##'"}],attrs:{"rules":_vm.rules.periodRules,"label":_vm.$t('c:stock-refill:Start period Month.Day')},model:{value:(_vm.inputPeriodFrom),callback:function ($$v) {_vm.inputPeriodFrom=$$v},expression:"inputPeriodFrom"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.##'),expression:"'##.##'"}],attrs:{"rules":_vm.rules.periodRules,"label":_vm.$t('c:stock-refill:End period Month.Day')},model:{value:(_vm.inputPeriodTo),callback:function ($$v) {_vm.inputPeriodTo=$$v},expression:"inputPeriodTo"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('Enabled'),"required":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('c:stock-refill:Notify me when ordering is required'),"required":""},model:{value:(_vm.notifyRequired),callback:function ($$v) {_vm.notifyRequired=$$v},expression:"notifyRequired"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('eon-button',{key:'new',attrs:{"id":"submit","template":'new',"click":_vm.submitForm}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }